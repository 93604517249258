import Vue from "vue";
import { NestedMultiSelectInstance } from "./NestedMultiSelectInstance";

type Answers = Array<{ location: string; date: string }>;

export async function validations(state: NestedMultiSelectInstance): Promise<boolean> {
  state.state = null;

  if (state.selected.length == 0) {
    state.state = false;
    return false;
  }

  let dateAndOtherValidation: boolean = true;

  if (!validateDatePickers(state)) {
    dateAndOtherValidation = false;
  }

  if (!validateOtherText(state)) {
    dateAndOtherValidation = false;
  }

  if (!dateAndOtherValidation) {
    return false;
  }

  const tempAnswers = parseAnswers(state);

  state.answers = tempAnswers;
  console.log("Validation answers are", state.answers);
  state.state = true;
  return true;
}

function validateDatePickers(state: NestedMultiSelectInstance): boolean {
  let passed = true;
  state.selected.forEach((location) => {
    if (location == "Other") return;
    const index = state.options.findIndex((option) => option == location);

    if (state.dates[index] == "") {
      setDatePickerStates(state, index, false);
      passed = false;
    } else {
      setDatePickerStates(state, index, true);
    }
  });
  return passed;
}

function validateOtherText(state: NestedMultiSelectInstance): boolean {
  if (state.selected.includes("Other") && (state.otherText == "" || state.otherText == undefined)) {
    state.otherState = false;
    return false;
  }
  state.otherState = true;
  return true;
}

function parseAnswers(state: NestedMultiSelectInstance): Answers {
  const answers: Answers = [];

  state.selected.forEach((location) => {
    const index = state.options.findIndex((option) => option === location);
    if (location === "Other") {
      answers.push({ location: state.otherText, date: "" });
    } else {
      answers.push({ location: state.options[index], date: state.dates[index] });
    }
  });

  return answers;
}

export async function computes(state: NestedMultiSelectInstance): Promise<any> {
  return state.answers;
}

export function isSelected(state: NestedMultiSelectInstance, option: string): boolean {
  if (state.selected.includes(option)) {
    return true;
  }
  return false;
}

export function getIndex(state: NestedMultiSelectInstance, option: string): number {
  return state.options.indexOf(option);
}

export function organiseSelected(state: NestedMultiSelectInstance): string[] {
  const organisedArray: string[] = [];
  for (let i = 0; i < state.options.length; i++) {
    if (state.selected.includes(state.options[i])) {
      if (state.options[i] === "Other") {
        organisedArray.unshift(state.options[i]);
      } else {
        organisedArray.push(state.options[i]);
      }
    }
  }
  return organisedArray;
}

function setDatePickerStates(state: NestedMultiSelectInstance, index: number, value: boolean): void {
  Vue.set(state.datePickerStates, index, value);
}

export function setUpData(state: NestedMultiSelectInstance): void {
  state.options.forEach(() => {
    state.dates.push("");
    state.datePickerStates.push(null);
  });
}

export function toggleSelected(state: NestedMultiSelectInstance, option: string): void {
  const index = state.selected.indexOf(option);
  if (index > -1) {
    state.selected.splice(index, 1);
  } else {
    state.selected.push(option);
  }
}

export function parseExistingAnswer(state: NestedMultiSelectInstance): void {
  if (state.answer.length > 0) {
    state.answer.forEach((answer) => {
      const index = state.options.findIndex((option) => option === answer.location);
      if (index > -1) {
        state.selected.push(answer.location);
        state.dates[index] = answer.date;
      } else {
        state.selected.push("Other");
        state.otherText = answer.location;
      }
    });
  }
}
